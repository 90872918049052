import React, { useEffect } from "react";
import "./Subtotal.css";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "./StateProvider";
import { getBasketTotal } from "./reducer";
import { Button, Checkbox } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

function Subtotal() {
  const history = useHistory();
  const [{ user, basket }] = useStateValue();
  useEffect(() => {
    var val = document.getElementById("val");
    val.classList.toggle("transval");
  }, [getBasketTotal(basket)]);
  return (
    <div
      className="subtotal"
      data-aos="fade-down"
      data-aos-anchor-placement="center"
    >
      <CurrencyFormat
        renderText={(value) => (
          <>
            <p>
              Subtotal ({basket.length} items){" "}
              <p id="val">
                {" "}
                <strong>{value < 0 ? 0 : value}</strong>{" "}
              </p>
            </p>
            <small className="subtotal__gift">
              <Checkbox
                value="checkedA"
                inputProps={{ "aria-label": "Checkbox A" }}
              />
              This order contains a gift
            </small>
          </>
        )}
        value={getBasketTotal(basket)}
        decimalScale={2}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
      <Button
        onClick={(e) => history.push("/payment")}
        disabled={!user || basket.length == 0 ? true : false}
      >
        {" "}
        {basket.length <= 0
          ? "Your Basket is Empty"
          : user
          ? "Procced to Checkout"
          : "Login Required"}{" "}
      </Button>
      <Link to="/login">
        <div
          className="login__required"
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          {basket.length > 0 ? (!user ? "Login" : "") : ""}
        </div>
      </Link>
    </div>
  );
}

export default Subtotal;
